import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },

  {
    path: "/equipos",
    redirect: "/",
    name: "Teams",
    component: () => import("../components/Teams2"),
  },

  {
    path: "/equipos/:id",
    name: "TeamDetail",
    component: () => import("../views/TeamDetail8"),
  },

  {
    path: "/info",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    path: "/404",
    name: "Error",
    component: () => import("../views/404"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
