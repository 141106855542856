import Firebase from "firebase";

export const searchmModule = {
  namespaced: true,
  state: {
    search: "",
    teams: [],
  },
  getters: {
    searchedTeams(state) {
      if (state.search === "") {
        return state.teams;
      } else {
        return state.teams.filter(
          (team) =>
            team.name_short
              .toLowerCase()
              .includes(state.search.toLowerCase()) ||
            team.date.toLowerCase().includes(state.search.toLowerCase())
        );
      }
    },
  },
  mutations: {
    // setear busqueda
    SET_SEARCH(state, newSearch) {
      state.search = newSearch;
    },

    //setea productos
    SET_TEAMS(state, newTeam) {
      state.teams = newTeam;
    },
  },
  actions: {
    setSearch(context, newSearch) {
      if (typeof newSearch === "string") {
        context.commit("SET_SEARCH", newSearch);
      } else {
        console.warn(
          `newSearch debiese de ser de tipo string y recibir un tipo ${typeof newSearch}`
        );
      }
    },

    AllTeams(context) {
      Firebase.firestore()
        .collection("equipos")
        .get()
        .then((documents) => {
          const teams = [];
          documents.forEach((document) => {
            teams.push({ id: document.id, ...document.data() });
          });
          context.commit("SET_TEAMS", teams);
        });
    },
  },
};
