<template>
  <v-app>
    <Navbar />
    <Footer />

    <v-main class="main-bg">
      <v-container class="container-bg">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Firebase from "firebase";
export default {
  name: "App",

  data: () => ({
    equipos: [],
  }),

  components: {
    Navbar: () => import("./components/Base/Navbar2.vue"),
    Footer: () => import("./components/Base/Footer.vue"),
  },

  mounted() {
    Firebase.firestore()
      .collection("equipos")
      .get()
      .then((documents) => {
        documents.forEach((document) => {
          this.equipos.push({ id: document.id, ...document.data() });
        });
      });
  },
};
</script>

<style>
body,
html {
  margin: 0;
  width: 100%;
}
h1,
h2,
h3,
h4 {
  font-family: "Tourney", sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.main-bg {
  background-image: url(../public/assets/img/background.png);
  background-repeat: repeat;
}

.container-bg {
  background-color: white;
}
</style>
