import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { teamsModule } from "./modules/teams";
import { searchmModule } from "./modules/search";

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    teams: teamsModule,
    searchm: searchmModule,
  },
});
