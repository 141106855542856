import Firebase from "firebase";

export const teamsModule = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_TEAMS_DATA(state, newTeamsData) {
      state.data = newTeamsData;
    },
  },
  actions: {
    getAllTeams(context) {
      Firebase.firestore()
        .collection("equipos")
        .get()
        .then((documents) => {
          const teams = [];
          documents.forEach((document) => {
            teams.push({ id: document.id, ...document.data() });
          });
          context.commit("SET_TEAMS_DATA", teams);
        });
    },
  },
};
